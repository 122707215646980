import './App.css';
import Form from './components/form/Form';
import Header from './components/Header';
import { useEffect } from 'react';
import { fetchThemeSettings } from './features/themeSlice';
import { useDispatch, useSelector } from 'react-redux';
import Result from './components/result/Result';


function App() {
  
  const themeSetting = useSelector((state) => state.theme && state.theme.data && state.theme.data.design);
  const resultDetailData = useSelector((state) => state.resultDetail && state.resultDetail.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchThemeSettings (process.env.REACT_APP_THEME_API_URL));
  }, [dispatch]);
  return (
    <>
        <div className=' mx-auto'>
          {/* <div className='relative  bg-[url("https://img.freepik.com/premium-photo/diamond-jewelry-luxury-fashion-jewelry_550617-29947.jpg?w=996")] bg-cover'> */}
          <div className='relative  bg-white'>
          <div className='absolute top-0 left-0 h-full w-full'></div>
            <div className={`grid gap-[15px] sm:w-[90%] md:w-[80%] w-[95%] mx-auto md:grid-cols-1 mb-[30px]`}>
              <Form/>
            </div>
          </div>
          {
            resultDetailData &&  resultDetailData.resultview === true ? 
            <Result/>
              :''
          }
        </div>
    </>
  );
}

export default App;
