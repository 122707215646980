import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getProductSuggestions } from '../commonFun';

export default function Products() {
  const themeSetting = useSelector((state) => state.theme.data.design);
  const resultDetailData = useSelector((state) => state.resultDetail && state.resultDetail.data);
  const rudrakshList = useSelector((state) => state.theme && state.theme.data && state.theme.data.design.rudraksh);
  const gemsList = useSelector((state) => state.theme && state.theme.data && state.theme.data.design.gems);
  const braceletList = useSelector((state) => state.theme && state.theme.data && state.theme.data.design.bracelet);

  const [products,setProducts] = useState("");
  
  useEffect(() =>{
    const rudrakshName = resultDetailData && resultDetailData.data && resultDetailData.data.rudraksh[0].name;
    const gemsName = resultDetailData && resultDetailData.data && resultDetailData.data.gems[0].name;
    const braceletName = resultDetailData && resultDetailData.data && resultDetailData.data.bracelet;

    let braceletNameArr = [];
    braceletName && braceletName.forEach((value) =>{
      braceletNameArr.push(value.name)
    })
    const productList = {rudrakshList,braceletList,gemsList}
    let result = getProductSuggestions(gemsName,rudrakshName,braceletNameArr,productList)
    setProducts(result)
  },[resultDetailData,braceletList, gemsList, rudrakshList])
  
  return (
    <>
<div className="  flex justify-center items-center mx-auto rounded-bl-[40px] rounded-br-[40px] mt-[40px] font-castoro">
  <div className="md:px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-[40px] lg:grid-cols-2 xl:grid-cols-4 md:gap-[12px] sm:p-0">

    {
       products && products.rudrakshProduct && products.rudrakshProduct.map((value,index) =>{
        return (
          <div key={index} className="relative max-w-[384px] bg-white pb-[8px] rounded-[6px] h-full " style={{boxShadow: '0px 7px 25px -22px'}}>
            <div className="relative">
              <img className=" mx-auto rounded-[10px]" src={value.img} alt="Colors" />
            </div>
            <hr/>
              <h1 className="mt-[6px] text-mainColor text-[16px] font-[500] cursor-pointer text-center">{value.name}</h1>
              {/* <div className=' text-center border-t-2 border-b-2 bg-gray-50 rounded-sm ps-2  border-gray-200  top-[65%] min-h-[40px] w-full left-0 p-[2px] text-[12px]'>{value.description} </div> */}
              <div className="mt-[15px] mb-[20px] text-center">
                {/* <a target='_blank' style={{color: 'white'}} rel="noreferrer" href={value.link} className="text-[16px] py-[8px] px-[16px] bg-mainColor text-white  rounded-[4px]">Buy Now</a> */}
                <a target='_blank' style={{color: 'white'}} rel="noreferrer" href="https://thegemstoneco.com/collections/rudraksha-beads" className="text-[16px] py-[8px] px-[16px] bg-mainColor text-white  rounded-[4px]">Buy Now</a>
              </div>
          </div>          
        )
      })
    }

  {
      products && products.gemsProduct && products.gemsProduct.map((value,index) =>{
        return (
          <div key={index} className="relative max-w-[384px] bg-white pb-[8px] rounded-[6px] h-full" style={{boxShadow: '0px 7px 25px -22px'}}>
            <div className="relative">
              <img className=" mx-auto rounded-[10px]" src={value.img} alt="Colors" />
            </div>
<hr/>
              <h1 className="mt-[6px] text-mainColor text-[16px] font-[500] cursor-pointer text-center">{value.name}</h1>
              {/* <div className=' text-center border-t-2 border-b-2 bg-gray-50 rounded-sm ps-2  border-gray-200  top-[65%] min-h-[40px] w-full left-0 p-[2px] text-[12px]'>{value.description} </div> */}
              <div className="mt-[15px] mb-[20px] text-center">
              {/* <a target='_blank' style={{color: 'white'}} rel="noreferrer" href={value.link} className="text-[16px] py-[8px] px-[16px] bg-mainColor text-white  rounded-[4px]">Buy Now</a> */}
              <a target='_blank' style={{color: 'white'}} rel="noreferrer" href="https://thegemstoneco.com/collections/gemstones" className="text-[16px] py-[8px] px-[16px] bg-mainColor text-white  rounded-[4px]">Buy Now</a>
              </div>
          </div>          
        )
      })
    }


{
  products && products.braceletProduct && products.braceletProduct.map((productGroup, groupIndex) => {
    return(
    <div key={`group-${groupIndex}`}>
      {productGroup.map((value, index) => {
        return(
        <div key={`product-${groupIndex}-${index}`} className="relative max-w-[384px] h-full bg-white pb-[8px] rounded-[6px] " style={{boxShadow: '0px 7px 25px -22px'}}>
          <div className="relative">
            <img className=" mx-auto rounded-[10px]" src={value.img} alt={value.name} />
          </div>
<hr/>
              <h1 className="mt-[6px] text-mainColor text-[16px] font-[500] cursor-pointer text-center">{value.name}</h1>
              {/* <div className=' text-center border-t-2 border-b-2 bg-gray-50 rounded-sm ps-2  border-gray-200  top-[65%] min-h-[40px] w-full left-0 p-[2px] text-[12px]'>{value.description} </div> */}
              <div className="mt-[15px] mb-[20px] text-center">
              {/* <a target='_blank' style={{color: 'white'}} rel="noreferrer" href={value.link} className="text-[16px] py-[8px] px-[16px] bg-mainColor text-white  rounded-[4px]">Buy Now</a> */}
              <a target='_blank' style={{color: 'white'}} rel="noreferrer" href="https://thegemstoneco.com/collections/bracelets" className="text-[16px] py-[8px] px-[16px] bg-mainColor text-white  rounded-[4px]">Buy Now</a>
              </div>
        </div>
      )})
      }
    </div>
  )})
}

    
  </div>
</div>  
    </>
  )
}
