import React from 'react'
import FormDesign2 from './FormDesign2'

export default function Form() {
  return (
    <>
        <FormDesign2/>
    </>
  )
}
