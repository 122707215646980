import React from 'react'
import ResultTheme2 from './ResultTheme2';

export default function Result() {
return (
    <>
    <ResultTheme2/>
    </>
  )
}
