import React, { useEffect, useState } from 'react'
import Products from './Products'
import { useSelector } from 'react-redux';
import { getMoonSignSvg } from '../commonFun';

export default function ResultTheme2() {
    const themeSetting = useSelector((state) => state.theme && state.theme.data && state.theme.data.design);
    const resultDetailData = useSelector((state) => state.resultDetail && state.resultDetail.data);
    const resultDetail = resultDetailData.data;

    const [moonSignImg, setMoonSignSvg] = useState('');

    
  useEffect(() =>{
    let moonSignName = resultDetail && resultDetail.rashi && resultDetail.rashi.rashi;
    let signColor = "#074142";
    let moonSign = getMoonSignSvg(moonSignName,signColor)
    setMoonSignSvg(moonSign)
  },[resultDetail,themeSetting])
  return (
    <>
        {/* <div className='w-full bg-[url("https://img.freepik.com/premium-photo/diamond-jewelry-luxury-fashion-jewelry_550617-29947.jpg?w=996")] bg-no-repeat bg-cover md:bg-[#fff]' id='resultView'> */}
        <div className='w-full bg-[#f2f9fa]' id='resultView'>
            <div className=''>
                <div className='w-[90%] sm:w-[85%] mx-auto py-[40px] sm:px-[80px] rounded-[40px]'>
                    <div className='flex lg:flex-row flex-col w-full justify-center items-center gap-[20px]' >
                        <div className='flex items-center bg-black/10 backdrop-blur-md rounded-[22px] px-[20px] min-h-[175px] w-full'>
                            <div className='flex flex-col items-center'>
                                <svg className='w-[100px] sm:w-[120px]'>
                                    {moonSignImg}
                                </svg>
                            </div>
                            <div className='ms-[15px] text-[20px] sm:text-[25px] text-mainColor '>
                                <div className='font-[400] font-castoro'>Hello, {resultDetail && resultDetail.name}!</div>
                                <div className='leading-none font-castoro font-[400]'> Your Moon Sign is <b>{resultDetail && resultDetail.rashi.rashi}</b></div>
                            </div>
                        </div>

                        <div className='shadow-sm bg-black/10 backdrop-blur-md border text-[14px] md:text-[17px] overflow-hidden text-mainColor rounded-[22px] min-h-[175px] w-full'>
                            <div className='flex border-b px-[4px] sm:px-[25px]'>
                                <div className='p-[15px] border-r w-[30%] overflow-hidden font-[700] font-castoro'>Gems</div>
                                <div className='p-[15px] leading-none w-[70%] font-castoro font-[400]'><span className=''> {resultDetail && resultDetail.gems[0].name} </span>, is best for you</div>
                            </div>
                            <div className='flex border-b px-[4px] sm:px-[25px]'>
                                <div className='p-[15px] border-r w-[30%] overflow-hidden font-[700] font-castoro'>Bracelet</div>
                                <div className='p-[15px] leading-none w-[70%] font-castoro font-[400]'>{resultDetail && resultDetail.bracelet[0].name}, {resultDetail && resultDetail.bracelet[1].name} is best for you</div>
                            </div>
                            <div className='flex px-[4px] sm:px-[25px]'>
                                <div className='p-[15px] border-r w-[30%] overflow-hidden font-[700] font-castoro'>Rudraksha</div>
                                <div className='p-[15px] leading-none w-[70%] font-castoro font-[400]'>{resultDetail && resultDetail.rudraksh[0].name}, is best for you</div>
                            </div>
                        </div>
                    </div>
                    
                        {/* <div  className='lg:flex justify-between gap-[20px]'>
                        <div style={{boxShadow: '0px 6px 27px -13px black'}} className='w-full bg-white text-[14px] md:text-[17px] font-castoro border overflow-hidden text-mainColor rounded-[22px]'>
                                <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-castoro'>Email - </span> {resultDetail && resultDetail.email} </div>
                                </div>
                                <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-castoro'>Phone - </span> {resultDetail && resultDetail.mobile} </div>
                                </div>
                                <div className='flex p-[15px] px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-castoro'>Gender - </span>{resultDetail && resultDetail.gender} </div>
                                </div>
                            </div>

                            <div style={{boxShadow: '0px 6px 27px -13px black'}} className='w-full bg-white text-[14px] md:text-[17px] font-castoro overflow-hidden border text-mainColor rounded-[22px] mt-[30px] lg:mt-0'>
                                <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-castoro'>Date of Birth - </span>{resultDetail && resultDetail.birth_date}</div>
                                </div>
                                <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-castoro'>Body Weight - </span>{resultDetail && resultDetail.body_weight}</div>
                                </div>
                                <div className='flex p-[15px] px-[20px] sm:px-[40px]'>
                                    <div className='leading-none'><span className='font-[500] font-castoro'>Birth Place - </span>{resultDetail && resultDetail.birth_place}</div>
                                </div>
                            </div>
                        </div> */}
                    
                    
                        {/* <div className='shadow-sm lg:w-[60%] bg-black/10 backdrop-blur-md border text-[14px] md:text-[17px] overflow-hidden text-mainColor rounded-[22px] mt-[10px]'>
                            <div className='flex border-b px-[4px] sm:px-[25px]'>
                                <div className='p-[15px] border-r w-[30%] overflow-hidden font-[700] font-castoro'>Gems</div>
                                <div className='p-[15px] leading-none w-[70%] font-castoro font-[400]'><span className=''> {resultDetail && resultDetail.gems[0].name} </span>, is best for you</div>
                            </div>
                            <div className='flex border-b px-[4px] sm:px-[25px]'>
                                <div className='p-[15px] border-r w-[30%] overflow-hidden font-[700] font-castoro'>Bracelet</div>
                                <div className='p-[15px] leading-none w-[70%] font-castoro font-[400]'>{resultDetail && resultDetail.bracelet[0].name}, {resultDetail && resultDetail.bracelet[1].name} is best for you</div>
                            </div>
                            <div className='flex px-[4px] sm:px-[25px]'>
                                <div className='p-[15px] border-r w-[30%] overflow-hidden font-[700] font-castoro'>Rudraksha</div>
                                <div className='p-[15px] leading-none w-[70%] font-castoro font-[400]'>{resultDetail && resultDetail.rudraksh[0].name}, is best for you</div>
                            </div>
                        </div> */}
                <Products/>
                </div>
            </div>

        </div>
    </>
  )
}
